@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~react-image-gallery/styles/css/image-gallery.css";


.sidebar-toggle {
  position: relative;
  float: left;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
}

.sidebar-toggle svg {
  width: 20px;
  height: 20px;
}

.sidebar-content {
  margin-top: 20px;
}

.type-option:hover,
.type-option-hover {
  background-color: #eee;
  cursor: pointer;
}

.variant-image {
  max-height: 50px;
}

.nav-logo {
  max-width: 6em;
}

.carousel-indicators [data-bs-target] {
  background-color: #f44d4d;
}

.apiImage {
  max-width: 400px;
}

@media (max-width: 767px) {
  .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  header h1 {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  header h1 {
    font-size: 28px;
  }
}

.sidebar-collapse-btn {
  display: none;
  font-size: 14px;
}

@media (max-width: 767px) {
  .sidebar-collapse-btn {
    display: inline-block;
  }
}

 

@media (max-width: 767px) {
  .collapsed {
    display: block;
    min-width: 50px;
  }

  .collapsed .sidebar-content {
    display: none;
  }
}

@media (max-width: 767px) {
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}


.collapse.show {
  visibility: visible !important;
}
 
.container-fluid {
  --bs-gutter-x: 0;
}