.product-details {
    display: flex;
    flex-direction: column;
  }
  
  .variant-table {
   
  }
  
  .d-flex {
  
}